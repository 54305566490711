import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"
import { getLinkProps } from "../../helpers"
import {
  PageBuilderActiviteitCTAFragment,
  PageBuilderCTAFragment,
  PageBuilderErvaringCTAFragment,
  PageBuilderNieuwsCTAFragment,
  PageBuilderVacatureCTAFragment,
} from "../../__generated__/graphql-gatsby"

interface IProps {
  block:
    | PageBuilderCTAFragment
    | PageBuilderErvaringCTAFragment
    | PageBuilderActiviteitCTAFragment
    | PageBuilderNieuwsCTAFragment
    | PageBuilderVacatureCTAFragment
}

type Align = "left" | "center" | "right"

const StyledButton = styled(Button)`
  text-transform: none;
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(6)}px`};
  font-size: 22px;
`

const Box = styled.div<{ align: Align }>`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  ${({ align }) => {
    switch (align) {
      case `right`:
        return `justify-content: flex-end;`
      case "center":
        return `justify-content: center;`
      case `left`:
      default:
        return `justify-content: flex-start;`
    }
  }}
`

const CTAWrapper = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
`

const Spacer = styled.div`
  min-height: ${({ theme }) => theme.spacing(2)}px;
`

const CTA: React.FC<IProps> = ({ block }) => (
  <CTAWrapper maxWidth="sm">
    {block.titel && (
      <Typography
        variant="h4"
        component="div"
        color="inherit"
        align={block.uitlijningTitel as Align}
      >
        {block.titel}
      </Typography>
    )}
    {block.subtitel && (
      <Typography
        variant="h6"
        component="div"
        color="inherit"
        align={block.uitlijningTitel as Align}
      >
        {block.subtitel}
      </Typography>
    )}
    <Spacer />
    <Box align={block.uitlijningKnop as Align}>
      <StyledButton
        size="large"
        color="secondary"
        variant="contained"
        {...getLinkProps(String(block.linkKnop?.url), {
          target: block.linkKnop?.target,
        })}
      >
        {block.linkKnop?.title}
      </StyledButton>
    </Box>
  </CTAWrapper>
)

export default CTA
